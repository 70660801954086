import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 44 44">
    <path
      fill={color}
      d="M23.833 38.5H16.5v-22h7.333v3.667a8.467 8.467 0 016.463-3.233 8.25 8.25 0 018.204 8.274V38.5h-7.333V26.125a4.153 4.153 0 00-4.12-3.564 3.327 3.327 0 00-3.214 3.564V38.5zm-11 0H5.5v-22h7.333v22zM9.167 12.833a3.667 3.667 0 110-7.333 3.667 3.667 0 010 7.333z"
    />
  </svg>
);
